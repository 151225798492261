/*============================================================================================*/
/* 1.  SITE STRUCTURE and TYPOGRAPHY */
/*============================================================================================*/
/*---------------------policy_page--------------------------*/
#policy_page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #4b9e63;
    color: #fff;
    padding: 30px;
    /**text-align:left;*/
    text-align: justify;
    text-justify: inter-word;
}
/* parti des condition*/
#ConditionsG {
    font-weight: bold;
    text-decoration: underline;
}
#policy_page h2 {
    color: #fff;
    font-size: 35px;
    font-weight: bold;
}
#policy_page h6 {
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    text-decoration: underline;
}
#policy_page p {
    font-size: 21px;
    font-size: 1.3125rem;
}
/**policy_page end/
/*-------- Preloader --------*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-color: #fff;
    z-index: 999999;
}

[data-loader='circle-side'] {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
    -webkit-animation: circle infinite 0.95s linear;
    -moz-animation: circle infinite 0.95s linear;
    -o-animation: circle infinite 0.95s linear;
    animation: circle infinite 0.95s linear;
    border: 2px solid #4b9e63;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-right-color: rgba(0, 0, 0, 0.2);
    border-bottom-color: rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    border-radius: 100%;
}

@-webkit-keyframes circle {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes circle {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes circle {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes circle {
    0% {
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.body_freeze {
    overflow: hidden !important;
}

.extra_img {
    align-self: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.extra_img label {
    align-self: flex-start;
    margin-left: 10px;
}
/*-------- 1.1 Typography --------*/
/* rem reference
10px = 0.625rem
12px = 0.75rem
14px = 0.875rem
16px = 1rem (base)
18px = 1.125rem
20px = 1.25rem
24px = 1.5rem
30px = 1.875rem
32px = 2rem
36px= 2.571428571rem
42px= 3rem
48px= 3.428571429rem
64px= 4.57142857rem
72px= 5.142857143rem
*/
html * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: #fff;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.4;
    font-family: 'Poppins', Helvetica, sans-serif;
    color: #555;
}

main {
    background: #f5f8fa;
    position: relative;
    z-index: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #333;
}

p {
    margin-bottom: 30px;
}

hr {
    margin: 15px 0 30px 0;
    border-color: #ededed;
}

hr.double {
    margin: 15px 0 30px 0;
    border-color: #ededed;
    border-width: 2px;
}

ul,
ol {
    list-style: none;
    margin: 0 0 25px 0;
    padding: 0;
}

p {
    margin-bottom: 25px;
}

p.lead {
    font-weight: 300;
    font-size: 20px;
    font-size: 1.25rem;
}

strong {
    font-weight: 600;
}

.main_title {
    text-align: center;
    margin-bottom: 45px;
}
@media (max-width: 767px) {
    .main_title {
        /* For less than 767px */
        margin-bottom: 15px;
    }
}
.main_title h2 {
    font-weight: 300;
    letter-spacing: -1px;
    font-size: 36px;
    margin: 0;
    text-transform: uppercase;
}
@media (max-width: 767px) {
    .main_title h2 {
        /* For less than 767px */
        font-size: 32px;
        font-size: 2rem;
    }
}
@media (max-width: 575px) {
    .main_title h2 {
        /* For less than 575px */
        font-size: 24px;
        font-size: 1.5rem;
    }
}
@media (max-width: 767px) {
    .main_title h1 {
        /* For less than 767px */
        font-size: 32px;
        font-size: 2rem;
    }
}
.main_title p {
    font-weight: 300;
    font-size: 20px;
    color: #777;
    padding: 0 10%;
}
@media (max-width: 767px) {
    .main_title p {
        /* For less than 767px */
        font-size: 18px;
        font-size: 1.125rem;
        padding: 0;
    }
}
@media (max-width: 575px) {
    .main_title p {
        /* For less than 575px */
        font-size: 16px;
        font-size: 1rem;
    }
}

.main_title_2 {
    text-align: center;
    margin-bottom: 30px;
}
.main_title_2 h3 {
    font-weight: 300;
    font-size: 28px;
    font-size: 1.75rem;
    margin: 0;
    text-transform: uppercase;
}
.main_title_2 p {
    font-weight: 300;
    font-size: 20px;
    font-size: 1.25rem;
    color: #777;
}

.main_title_3 {
    background-color: #4b9e63;
    background-color: #4b9e63;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    position: relative;
    margin-bottom: 60px;
}
@media (max-width: 991px) {
    .main_title_3 {
        /* For less than 991px */
        margin-bottom: 30px;
    }
}
.main_title_3 h3 {
    font-size: 16px;
    font-size: 1rem;
    text-transform: uppercase;
    margin: 5px 0 0 0;
    position: relative;
    color: #fff;
}
.main_title_3 h3 strong {
    background-color: #0086ad;
    color: #fff;
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    line-height: 24px;
    display: inline-block;
    width: 24px;
    height: 24px;
    font-size: 14px;
    font-size: 0.875rem;
    margin-right: 10px;
    position: relative;
    top: -2px;
}
.main_title_3 h3:before,
.main_title_3 h3:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 54px;
    bottom: -42px;
    display: block;
    z-index: 9;
}
@media (max-width: 991px) {
    .main_title_3 h3:before,
    .main_title_3 h3:after {
        /* For less than 991px */
        display: none;
    }
}
.main_title_3 h3:before {
    left: 25%;
    background: url(../img/arrow_left.png) no-repeat 0 0;
}
.main_title_3 h3:after {
    right: 25%;
    background: url(../img/arrow_right.png) no-repeat 0 0;
}

.main_title_4 {
    background-color: #4b9e63;
    background-color: #4b9e63;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 30px;
}
@media (max-width: 991px) {
    .main_title_4 {
        /* For less than 991px */
        margin-bottom: 30px;
    }
}
.main_title_4 h3 {
    font-size: 16px;
    font-size: 1rem;
    text-transform: uppercase;
    margin: 0;
    color: #fff;
}
.main_title_4 h3 i {
    position: relative;
    top: 1px;
    margin-right: 5px;
}

/*-------- 1.2 Buttons --------*/
/*General links color*/
a {
    color: #0086ad;
    text-decoration: none;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    outline: none;
}
a:hover,
a:focus {
    color: #333;
    text-decoration: none;
    outline: none;
}

a.btn_1,
.btn_1 {
    border: none;
    color: #fff;
    background: #4b9e63;
    cursor: pointer;
    padding: 7px 20px;
    display: inline-block;
    outline: none;
    font-size: 14px;
    font-size: 0.875rem;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    border-radius: 25px;
    font-weight: 500;
}
a.btn_1.medium,
.btn_1.medium {
    padding: 12px 45px;
    font-size: 16px;
    font-size: 1rem;
}
a.btn_1.full-width,
.btn_1.full-width {
    display: block;
    text-align: center;
    padding: 12px 45px;
    font-size: 16px;
    font-size: 1rem;
    width: 100%;
}
a.btn_1:hover,
.btn_1:hover {
    background: #74d1c6;
}
a.btn_1.outline,
.btn_1.outline {
    background: transparent;
    border: 2px solid #0086ad;
    color: #0086ad;
}
a.btn_1.outline:hover,
.btn_1.outline:hover {
    background: #0086ad;
    color: #fff;
}

/*-------- 1.3 Structure --------*/
/* Header */
header {
    padding: 10px 0;
    border-bottom: 1px solid transparent;
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient(
        left,
        rgba(255, 255, 255, 1) 0%,
        rgba(246, 246, 246, 1) 47%,
        rgba(237, 237, 237, 1) 100%
    );
    background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 1) 0%,
        rgba(246, 246, 246, 1) 47%,
        rgba(237, 237, 237, 1) 100%
    );
    background: -o-linear-gradient(
        left,
        rgba(255, 255, 255, 1) 0%,
        rgba(246, 246, 246, 1) 47%,
        rgba(237, 237, 237, 1) 100%
    );
    background: -ms-linear-gradient(
        left,
        rgba(255, 255, 255, 1) 0%,
        rgba(246, 246, 246, 1) 47%,
        rgba(237, 237, 237, 1) 100%
    );
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0%,
        rgba(246, 246, 246, 1) 47%,
        rgba(237, 237, 237, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed', GradientType=1 );
}
header.header_sticky {
    width: 100%;
    z-index: 999;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@media (max-width: 1900px) and (min-width: 0px) {
    .sc-launcher {
        right: 70px !important;
        bottom: 70px !important;
    }
    .sc-launcher .sc-open-icon,
    .sc-launcher .sc-closed-icon {
        right: 70px !important;
        bottom: 70px !important;
    }
}
​ header.header_sticky.sticky {
    border-bottom: 1px solid #ededed;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
}
header.header_map {
    width: 100%;
    border-bottom: 1px solid #ededed;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
}
header.static {
    position: static;
    border-bottom: 1px solid #ededed;
    z-index: 999;
    position: relative;
}

ul#top_access {
    float: right;
    position: relative;
    top: 3px;
    margin: -1px 0 0 0;
}
@media (max-width: 991px) {
    ul#top_access {
        right: 30px;
    }
}
ul#top_access li {
    display: inline-block;
    position: relative;
    padding: 0;
    margin-left: 15px;
}
ul#top_access li i {
    font-size: 34px;
    font-size: 2.125rem;
}
ul#top_access li a {
    color: #4b9e63;
}
ul#top_access li a:hover {
    color: #333;
}
ul#top_access li#user {
    position: relative;
    padding: 10px 0 0 60px;
    font-weight: 500;
}
ul#top_access li#user figure {
    position: absolute;
    left: 15px;
    top: 0;
    width: 35px;
    height: 35px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}
ul#top_access li#user figure img {
    width: auto;
    height: 35px;
}
@media (max-width: 991px) {
    ul#top_access li#user figure {
        /* For less than 991px */
        text-indent: 0;
    }
}
@media (max-width: 991px) {
    ul#top_access li#user {
        /* For less than 991px */
        text-indent: -9999px;
        padding: 0;
        margin: 2px 10px 0 0;
    }
}
.profile_row {
    display: flex !important;
    flex-direction: column !important;
}
.profile_detail {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    align-self: center;
    align-content: center;
    justify-content: center;
}
.profile ul.contacts {
    align-self: flex-start !important;
}
@media (max-width: 750px) {
    .profile_row {
        display: flex !important;
        flex-direction: column !important;
    }
}
.image_avatars_profile {
    margin: 0 auto;
    height: 100px !important;
    width: 100px !important;
}

.image_avatars {
    position: absolute !important;
    left: 10px !important;
 top: 20px !important;
  height: 100px !important;
  width: 100px !important;
}

@media (max-width: 750px) {
    .image_avatars {
        position: absolute !important;
        left: 1px !important;
        height: 80px !important;
        width: 80px !important;
    }
}
#sc-launcher {
    position: absolute;
    z-index: 9999 !important;
}
.sc-header {
    background-color: #008a69 !important;
}
/* logo home with h1 */
#logo_container {
    display: flex;
    flex-direction: row;
}
#logo_container img {
    margin-right: 5px;
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;
    justify-content: center;
}
@media (max-width: 375px) {
    #logo_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 90px;
    }
}
#logo_home {
    display: flex;
    flex-direction: column;
}
#logo_home .spanP {
    text-transform: uppercase;
    font-family: 'Cairo', sans-serif;
    font-size: 1.5rem;
    background: -webkit-linear-gradient(#3dae9f, #54b06e);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#logo_home .spanS {
    text-transform: capitalize;
    font-family: 'Cairo', sans-serif;
    font-size: 0.7rem;
    background: -webkit-linear-gradient(#3dae9f, #54b06e);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 0.1rem;
}
#logo_home_v2 {
    display: flex;
    flex-direction: column;
}
#logo_home_v2 .spanP {
    text-transform: uppercase;
    font-family: 'Cairo', sans-serif;
    font-size: 1.5rem;
    background: #ffffff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#logo_home_v2 .spanS {
    text-transform: capitalize;
    font-family: 'Cairo', sans-serif;
    font-size: 0.7rem;
    background: #ffffff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 0.1rem;
}
.logo_footer {
    margin-bottom: 5%;
}
.img_maroc {
    width: 70px;
    margin-left: 10%;
}
.rightHeader {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
}
.rightHeaderLogo {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
}
.rightHeaderUsers {
    display: flex;
    align-self: center;
    align-items: center;
    align-content: center;
    justify-content: center;
}
/* Footer */
footer {
    background-color: #fff;
    color: #555;
}
footer h5 {
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #999;
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    footer h5 {
        /* For less than 767px */
        margin-bottom: 10px;
    }
}
@media (max-width: 767px) {
    footer ul {
        /* For less than 767px */
        margin-bottom: 35px;
    }
}
footer ul li {
    margin-bottom: 10px;
}
footer ul li a {
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    position: relative;
    color: #555;
}
footer ul li a:hover {
    color: #0086ad;
}
footer ul li a i {
    margin-right: 10px;
    color: #fff;
}
footer ul.links li a:hover {
    -webkit-transform: translate(5px, 0);
    -moz-transform: translate(5px, 0);
    -ms-transform: translate(5px, 0);
    -o-transform: translate(5px, 0);
    transform: translate(5px, 0);
}
footer ul.links li a:hover:after {
    opacity: 1;
}
footer ul.links li a:after {
    font-family: 'ElegantIcons';
    content: '\24';
    position: absolute;
    margin-left: 5px;
    top: 1px;
    opacity: 0;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}
footer ul.contacts li a i {
    margin-right: 10px;
    color: #0086ad;
}
footer #copy {
    text-align: right;
    font-size: 13px;
    font-size: 0.8125rem;
    color: #999;
}
@media (max-width: 767px) {
    footer #copy {
        /* For less than 767px */
        text-align: left;
        margin-bottom: 25px;
    }
}

.follow_us ul {
    margin: 0;
}
.follow_us ul li {
    display: inline-block;
    margin-right: 10px;
    font-size: 16px;
    font-size: 1rem;
}
.follow_us ul li a i {
    color: #555;
}
.follow_us ul li a i:hover {
    color: #0086ad;
}

/* Addtional links / Footer */
ul#additional_links {
    margin: 0;
    font-size: 13px;
    font-size: 0.8125rem;
    color: #999;
}
ul#additional_links li {
    display: inline-block;
    margin-right: 15px;
}
ul#additional_links li:first-child {
    margin-right: 20px;
}
ul#additional_links li:last-child:after {
    content: '';
}
ul#additional_links li a {
    opacity: 0.5;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
ul#additional_links li a:hover {
    opacity: 1;
}
ul#additional_links li:after {
    content: '|';
    font-weight: 300;
    position: relative;
    left: 10px;
}

/* Wow on scroll event */
.wow {
    visibility: hidden;
}

/*============================================================================================*/
/* 2.  CONTENT */
/*============================================================================================*/
/*-------- 2.1 Home--------*/
.hero_home {
    height: 500px;
    width: 100%;
    display: table;
}
@media (max-width: 767px) {
    .hero_home {
        /* For less than 767px */
        height: 450px;
    }
}
.hero_home.version_1 {
    background: #4b9e63 url(../img/hero_home_bg_1.svg) no-repeat center bottom;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
@media (max-width: 767px) {
    .hero_home.version_1 {
        /* For less than 767px */
        height: 450px;
    }
}
.hero_home.version_1 .content {
    background-color: #363768;
    background-color: rgba(63, 64, 121, 0);
}
.hero_home.version_2 {
    background: none;
    position: relative;
    overflow: hidden;
    height: 500px;
    display: block;
}
.hero_home.version_2:before {
    background: #4b9e63 url(http://via.placeholder.com/1600x1067.jpg) no-repeat
        center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    animation: pop-in 3s 0.3s cubic-bezier(0, 0.5, 0, 1) forwards;
    content: '';
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
}
@media (max-width: 767px) {
    .hero_home.version_2 {
        /* For less than 767px */
        height: 450px;
    }
}
.hero_home.version_2 .content {
    background-color: #3e3f77;
    background-color: rgba(63, 64, 121, 0.9);
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
@media (max-width: 991px) {
    .hero_home.version_2 .content {
        /* For less than 991px */
        padding: 0 10%;
    }
}
@media (max-width: 767px) {
    .hero_home.version_2 .content {
        /* For less than 767px */
        padding: 0 15px;
    }
}
.hero_home.version_3 {
    background: #4b9e63 url(../img/hero_home_bg_1.svg) no-repeat center bottom;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
@media (max-width: 767px) {
    .hero_home.version_3 {
        /* For less than 767px */
        height: 450px;
    }
}
.hero_home.version_3 .content {
    background-color: #363768;
    background-color: rgba(63, 64, 121, 0);
    padding: 0 25%;
}
@media (max-width: 991px) {
    .hero_home.version_3 .content {
        /* For less than 991px */
        padding: 0 10%;
    }
}
@media (max-width: 767px) {
    .hero_home.version_3 .content {
        /* For less than 767px */
        padding: 0 15px;
    }
}
.hero_home.version_3 .content p {
    margin: 0 0 45px 0;
}
.hero_home .content {
    display: table-cell;
    padding: 0;
    vertical-align: middle;
    text-align: center;
    font-size: 21px;
    font-size: 1.3125rem;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    background-color: #3e3f77;
    background-color: rgba(63, 64, 121, 0.9);
}
@media (max-width: 991px) {
    .hero_home .content {
        /* For less than 991px */
        padding: 0 30px;
    }
}
@media (max-width: 767px) {
    .hero_home .content {
        /* For less than 767px */
        font-size: 16px;
        font-size: 1rem;
        padding: 0 15px;
    }
}
.hero_home .content h3 {
    margin: 0 0 0 0;
    font-size: 60px;
    font-size: 3.75rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
}
@media (max-width: 767px) {
    .hero_home .content h3 {
        /* For less than 767px */
        font-size: 42px;
        font-size: 2.625rem;
    }
}
@media (max-width: 575px) {
    .hero_home .content h3 {
        /* For less than 575px */
        font-size: 32px;
        font-size: 2rem;
    }
}

/* Animations hero */
@keyframes pop-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
        transform: scale(1.2);
    }
}
/* Delay */
.hero_home .content h3.animated,
#hero_video .content h3.animated {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    animation-duration: 0.4s;
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
}
.hero_home .content p.animated,
#hero_video .content p.animated {
    animation-delay: 1.3s;
    -webkit-animation-delay: 1.3s;
    -moz-animation-delay: 1.3s;
    animation-duration: 0.4s;
    -webkit-animation-duration: 0.4s;
    -moz-animation-duration: 0.4s;
}
.hero_home .content form.animated,
#hero_video .content form.animated {
    animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    -moz-animation-duration: 0.3s;
}
.hero_home .content .btn_1.animated,
#hero_video .content .btn_1.animated {
    animation-delay: 2s;
    -webkit-animation-delay: 2s;
    -moz-animation-delay: 2s;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
}

#custom-search-input {
    margin: 0;
    top: 0;
    padding: 0;
    width: 650px;
    margin: auto;
    position: relative;
}
@media (max-width: 991px) {
    #custom-search-input {
        /* For less than 991px */
        width: auto;
    }
}
#custom-search-input .search-query {
    width: 50%;
    height: 50px;
    border: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    font-weight: 500;
    font-size: 16px;
    color: #333;
    background: #fff5f5;
}
#custom-search-input .search-query:focus {
    outline: none;
}
.MuiInputBase-root {
    font-size: 16px !important;
}
#custom-search-input .search-select-query {
    width: 50%;
    height: 50px;
    border: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    font-weight: 500;
    font-size: 16px;
    color: #333;
    background: #fff5f5;
}
#custom-search-input .search-select-query:focus {
    outline: none;
}
@media (max-width: 575px) {
    #custom-search-input .search-query {
        /* For less than 575px */
        padding-left: 15px;
    }
    #custom-search-input .search-select-query {
        /* For less than 575px */
        padding-left: 15px;
    }
}
#custom-search-input ul {
    text-align: center;
    margin-top: 25px;
}
#custom-search-input ul li {
    display: inline-block;
    margin-right: 5px;
    font-size: 13px;
    font-size: 0.8125rem;
    font-weight: 600;
}
#custom-search-input ul li input[type='radio'] {
    display: none;
}
#custom-search-input ul li input[type='radio']:checked + label,
#custom-search-input ul li input[type='radio']:checked + label:hover {
    background-color: #74d1c6;
    color: #fff;
}
#custom-search-input ul li input[type='radio']:hover + label {
    background-color: #0086ad;
    color: #fff;
}
#custom-search-input ul li label {
    display: inline-block;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding: 8px 10px 6px 10px;
    line-height: 1;
    text-shadow: none;
    min-width: 80px;
    text-align: center;
    cursor: pointer;
}
#custom-search-input ul li:last-child {
    margin-right: 0;
}
#custom-search-input input[type='submit'] {
    position: absolute;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    right: 0;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    font-size: 1rem;
    top: 0;
    border: 0;
    padding: 0 25px;
    height: 50px;
    cursor: pointer;
    outline: none;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    background-color: #74d1c6;
}
#custom-search-input input[type='submit']:hover {
    background-color: #0086ad;
}
@media (max-width: 575px) {
    #custom-search-input input[type='submit'] {
        /* For less than 575px */
        text-indent: -999px;
        background: #74d1c6 url(../img/search.svg) no-repeat center center;
    }
}

.box_feat {
    background-color: #fff;
    padding: 165px 45px 20px 45px;
    text-align: center;
    position: relative;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 25px;
}
.box_feat h3 {
    text-transform: uppercase;
    font-size: 21px;
    font-size: 1.3125rem;
    margin-top: 25px;
    color: #0086ad;
}
.box_feat#icon_1 {
    background: #fff url(../img/icon-home-1.svg) no-repeat center 45px;
}
.box_feat#icon_2 {
    background: #fff url(../img/icon-home-2.svg) no-repeat center 45px;
}
.box_feat#icon_3 {
    background: #fff url(../img/icon-home-3.svg) no-repeat center 45px;
}
.box_feat span {
    position: absolute;
    top: 50%;
    right: -48px;
    margin: -42px 0 0 0;
    width: 70px;
    height: 70px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #ededed;
    background: #fff url(../img/arrow-gray-1.svg) no-repeat center center;
    display: block;
    z-index: 9;
}
@media (max-width: 991px) {
    .box_feat span {
        /* For less than 991px */
        top: 100%;
        left: 50%;
        margin: -20px 0 0 -35px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

.list_title {
    background-color: #4b9e63;
    color: #fff;
    margin-bottom: 5px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding: 30px 0;
    text-align: center;
}
.list_title i {
    font-size: 42px;
    font-size: 2.625rem;
    margin-bottom: 15px;
    display: inline-block;
}
@media (max-width: 575px) {
    .list_title i {
        /* For less than 575px */
        display: none;
    }
}
.list_title h3 {
    font-size: 18px;
    font-size: 1.125rem;
    margin: 0;
    color: #f8f8f8;
    text-transform: uppercase;
}
@media (max-width: 575px) {
    .list_title {
        /* For less than 575px */
        text-align: left;
        padding: 15px;
    }
}

.list_home ul {
    text-align: left;
}
.list_home ul li a {
    background-color: #fff;
    padding: 10px;
    display: block;
    margin-bottom: 5px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    font-weight: 500;
    color: #555;
}
.list_home ul li a strong {
    background-color: #f8f8f8;
    color: #999;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1;
    padding: 6px;
    display: inline-block;
    margin-right: 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
}
.list_home ul li a:after {
    font-family: 'ElegantIcons';
    content: '\24';
    right: 15px;
    top: 15px;
    position: absolute;
    color: #0086ad;
}
.list_home ul li a:hover {
    background-color: #0086ad;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
    color: #fff;
}
.list_home ul li a:hover:after {
    color: #fff;
}

/*Carousel home page*/
#reccomended,
#staff {
    margin-top: 40px;
}
@media (max-width: 767px) {
    #reccomended,
    #staff {
        /* For less than 767px */
        margin: 0 0 15px 0;
    }
}
#reccomended .item .title,
#staff .item .title {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    z-index: 9;
}
#reccomended .item .title h4,
#staff .item .title h4 {
    background-color: #4b9e63;
    background-color: #4b9e63;
    display: inline-block;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
    color: #fff;
    padding: 10px 15px;
    margin: 0;
    font-size: 16px;
    font-size: 1rem;
    opacity: 0;
}
#reccomended .item .title h4 em,
#staff .item .title h4 em {
    display: block;
    font-size: 13px;
    font-size: 13px;
    font-size: 0.8125rem;
    font-weight: 300;
    margin-top: 3px;
}
#reccomended .item a,
#staff .item a {
    position: relative;
    display: block;
}
#reccomended .item a .views,
#staff .item a .views {
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #4b9e63;
    background-color: #4b9e63;
    color: #fff;
    box-sizing: border-box;
    padding: 3px 6px 4px 5px;
    line-height: 1;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 12px;
    font-size: 0.75rem;
    opacity: 0;
}
#reccomended .item a .views i,
#staff .item a .views i {
    font-size: 16px;
    font-size: 1rem;
    font-weight: normal;
    margin-right: 3px;
    position: relative;
    top: 1px;
}

#reccomended .owl-item,
#staff .owl-item {
    opacity: 0.5;
    transform: scale(0.85);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(0.85, 0.85);
    transition: all 0.3s ease-in-out 0s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
}

#reccomended .owl-item.active.center,
#staff .owl-item.active.center {
    opacity: 1;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1, 1);
    transform: scale(1);
}

#reccomended .owl-item.active.center .item .title h4,
#staff .owl-item.active.center .item .title h4,
#reccomended .owl-item.active.center .item .views,
#staff .owl-item.active.center .item .views {
    opacity: 1;
}

.owl-theme .owl-dots {
    margin-top: 25px !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #0086ad !important;
}

a.box_cat_home {
    background-color: #fff;
    display: block;
    padding: 25px 15px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 25px;
    position: relative;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    color: #999;
}
a.box_cat_home i {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 18px;
    font-size: 1.125rem;
    color: #0086ad;
    opacity: 0;
    -moz-transition: all 0.6s ease-in-out;
    -o-transition: all 0.6s ease-in-out;
    -webkit-transition: all 0.6s ease-in-out;
    -ms-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
}
a.box_cat_home h3 {
    font-size: 18px;
    font-size: 1.125rem;
    margin-top: 10px;
}
a.box_cat_home:hover {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
}
a.box_cat_home:hover i {
    opacity: 1;
}
a.box_cat_home ul {
    margin: 20px -15px -25px -15px;
    padding: 10px;
    border-top: 1px solid #ededed;
}
a.box_cat_home ul li {
    display: inline-block;
}
a.box_cat_home ul li:first-child {
    float: left;
}
a.box_cat_home ul li:last-child {
    float: right;
}
a.box_cat_home ul li strong {
    margin-right: 5px;
    font-weight: 600;
}

#app_section {
    background: #4b9e63 url(../img/hero_bg_1.svg) repeat fixed;
    padding: 90px 0 60px 0;
    color: #fff;
}
@media (max-width: 991px) {
    #app_section {
        /* For less than 991px */
        padding: 60px 0 35px 0;
    }
}
#app_section .app_buttons {
    position: relative;
}
.fadeInApple {
    margin-right: 1%;
}
#app_section .app_buttons svg {
    width: 70px;
    height: 70px;
    position: absolute;
    left: -65px;
    top: -55px;
}
@media (max-width: 767px) {
    #app_section .app_buttons svg {
        /* For less than 767px */
        display: none;
    }
}
#app_section .app_buttons svg .draw-arrow {
    stroke-width: 4;
    stroke: #fff02a;
    fill: none;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-iteration-count: once;
    -webkit-animation-iteration-count: once;
    -moz-animation-iteration-count: once;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    animation-duration: 2s;
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
}
#app_section .app_buttons svg .draw-arrow.tail-1 {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
}
#app_section .app_buttons svg .draw-arrow.tail-2 {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
}
#app_section small {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 13px;
    font-size: 0.8125rem;
    opacity: 0.6;
}
#app_section h3 {
    color: #fff;
    font-size: 32px;
    font-size: 2rem;
}
#app_section h3 strong {
    color: #fff02a;
}
@media (max-width: 575px) {
    #app_section p {
        /* For less than 575px */
        font-size: 18px;
        font-size: 1.125rem;
        margin-bottom: 30px;
    }
}

.animated .draw-arrow {
    animation-name: draw;
    -webkit-animation-name: draw;
    -moz-animation-name: draw;
}

@-webkit-keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
@-moz-keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}
/* Home video background*/
.header-video {
    position: relative;
    overflow: hidden;
    background: #000;
}
@media (max-width: 991px) {
    .header-video {
        /* For less than 991px */
        height: 450px !important;
    }
}

#hero_video {
    position: relative;
    background-size: cover;
    color: #fff;
    width: 100%;
    display: table;
    height: 100%;
    z-index: 99;
}
#hero_video .content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    padding: 0 10%;
}
@media (max-width: 767px) {
    #hero_video .content {
        /* For less than 767px */
        padding: 0 15px;
    }
}
#hero_video .content h3 {
    color: #fff;
    font-size: 3.8rem;
    margin: 0;
    text-transform: uppercase;
    font-weight: 800;
}
@media (max-width: 767px) {
    #hero_video .content h3 {
        /* For less than 767px */
        font-size: 1.4rem;
    }
}
@media (max-width: 575px) {
    #hero_video .content h3 {
        /* For less than 575px */
        font-size: 1.8rem;
    }
}
#hero_video .content p {
    font-weight: 300;
    font-size: 1.5rem;
}
#hero_video .content p strong {
    font-weight: 600;
}
@media (max-width: 767px) {
    #hero_video .content p {
        /* For less than 767px */
        padding: 0;
        font-size: 1rem;
    }
}
@media (max-width: 575px) {
    #hero_video .content p {
        /* For less than 575px */
        font-size: 1.2rem;
    }
}

video {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.4;
}

iframe {
    height: 100%;
    width: 100%;
}

.teaser-video {
    width: 100%;
    height: auto;
}

.header-video--media {
    width: 100%;
    height: auto;
}

@media (max-width: 1024px) {
    #hero_video {
        background: #4b9e63 url(../img/hero_bg_1.svg) repeat fixed;
    }

    .header-video {
        background: none;
    }
}
/*-------- 2.2 Listing --------*/
#results {
    padding: 20px 0;
    color: #fff;
    background: #4b9e63;
}
@media (max-width: 767px) {
    #results {
        /* For less than 767px */
        padding: 10px 0;
    }
}
#results.is_stuck {
    z-index: 99;
}
#results h4 {
    color: #fff;
    margin: 10px 0 0 0;
    font-size: 18px;
    font-size: 1.125rem;
}
@media (max-width: 767px) {
    #results h4 {
        /* For less than 767px */
        display: none;
    }
}

.search_bar_wrapper {
    background-color: #4b9e63;
    padding: 15px;
    margin: 0 -15px 0 -15px;
}

.search_bar_list {
    position: relative;
}
.search_bar_list input[type='text'] {
    border: 0;
    height: 40px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding-left: 15px;
}
.search_bar_list input[type='submit'] {
    position: absolute;
    right: -1px;
    color: #fff;
    font-weight: 600;
    top: 0;
    border: 0;
    padding: 0 15px;
    height: 40px;
    cursor: pointer;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    background-color: #74d1c6;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.search_bar_list input[type='submit']:hover {
    background-color: #0086ad;
}

.box_list {
    background-color: #fff;
    display: block;
    position: relative;
    margin-bottom: 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
}
@media (max-width: 991px) {
    .box_list {
        /* For less than 991px */
        min-height: inherit;
    }
}
.box_list figure {
    margin-bottom: 0;
    overflow: hidden;
    position: relative;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    height: 260px;
}
@media (max-width: 767px) {
    .box_list figure {
        /* For less than 767px */
        height: 280px;
    }
}
.box_list figure .preview {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -12px;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    text-align: center;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    z-index: 2;
}
.box_list figure .preview span {
    background-color: #fcfcfc;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    border-radius: 20px;
    display: inline-block;
    color: #222;
    font-size: 0.75rem;
    padding: 5px 15px;
}
.box_list figure:hover .preview {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
}
.box_list figure a img {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.box_list figure a:hover img {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
}
.box_list .wrapper {
    padding: 25px;
}
.box_list .wrapper h3 {
    font-size: 1.25rem;
    margin-top: 0;
}
@media (max-width: 767px) {
    .box_list .wrapper p {
        /* For less than 767px */
        font-size: 13px;
        font-size: 0.8125rem;
    }
}
.box_list small {
    text-transform: uppercase;
    color: #999;
    font-weight: 600;
}
.box_list ul {
    width: 100%;
    padding: 20px 15px;
    border-top: 1px solid #ededed;
}
@media (max-width: 991px) {
    .box_list ul {
        /* For less than 991px */
        position: static;
    }
}
.box_list ul li {
    display: inline-block;
    color: #999;
    font-weight: 500;
}
.box_list ul li i {
    margin-right: 5px;
    font-weight: 500;
}
@media (max-width: 767px) {
    .box_list ul li:first-child {
        /* For less than 767px */
        display: none;
    }
}
.box_list ul li:nth-child(2) {
    display: none;
}
@media (max-width: 767px) {
    .box_list ul li:nth-child(2) {
        /* For less than 767px */
        display: inline-block;
    }
}
.box_list ul li a {
    color: #999;
    font-weight: 500;
}
.box_list ul li a:hover {
    color: #0086ad;
}
.box_list ul li:last-child {
    margin-right: 0;
    float: right;
}
.box_list ul li:last-child a {
    background: #fff;
    border: 2px solid #0086ad;
    color: #0086ad;
    padding: 5px 20px;
    font-weight: 500;
    line-height: 1;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
}
.box_list ul li:last-child a:hover {
    background: #0086ad;
    color: #fff;
}
.box_list.home ul li:first-child,
.box_list.home ul li:nth-child(2),
.box_list.home ul li:last-child {
    display: inline-block;
}

a.wish_bt {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 2;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 9px 10px;
    display: inline-block;
    color: #fff;
    line-height: 1;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
a.wish_bt:after {
    font-family: 'ElegantIcons';
    content: '\e030';
}
a.wish_bt:hover:after {
    content: '\e089';
    color: #0086ad;
}

.strip_list {
    margin-bottom: 25px;
    background-color: #fff;
    padding: 20px 15px 1px 115px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    position: relative;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}
.strip_list a.wish_bt {
    background-color: black;
    background-color: rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    color: #555;
}
.strip_list a.wish_bt:hover:after {
    color: #0086ad;
}
@media (max-width: 767px) {
    .strip_list {
        /* For less than 767px */
        padding: 20px 15px 15px 90px;
    }
}
.strip_list figure {
    width: 80px;
    height: 80px;
    position: absolute;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    left: 15px !important;
    top: 15px;
}
.strip_list figure img {
    width: auto;
    height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
}
@media (max-width: 767px) {
    .strip_list figure img {
        /* For less than 767px */
        height: 60px;
    }
}
@media (max-width: 767px) {
    .strip_list figure {
        /* For less than 767px */
        width: 60px;
        height: 60px;
    }
}
.strip_list h3 {
    font-size: 22px;
    font-size: 1.375rem;
    margin-top: 0;
    display: inline-block;
    margin-right: 10px;
}
.strip_list p {
    margin-bottom: 15px;
}
@media (max-width: 767px) {
    .strip_list p {
        /* For less than 767px */
        font-size: 13px;
        font-size: 0.8125rem;
    }
}
.strip_list small {
    color: #999;
    display: block;
    font-weight: 600;
    text-transform: uppercase;
}
.strip_list ul {
    border-top: 1px solid #ededed;
    padding: 10px 0px;
}
@media (max-width: 767px) {
    .strip_list ul {
        /* For less than 767px */
        margin: 15px -15px -15px -90px;
    }
}
.strip_list ul li {
    display: inline-block;
    margin-right: 5px;
    font-weight: 500;
}
.strip_list ul li a {
    color: #999;
    cursor: pointer;
}
.strip_list ul li a:hover {
    color: #0086ad;
}
.strip_list ul li:first-child:after {
    font-weight: 400;
    margin-left: 8px;
    color: #ccc;
}
.strip_list ul li:last-child {
    float: right;
    margin-right: 0;
}
.strip_list ul li:last-child a {
    border: 2px solid #0086ad;
    color: #0086ad;
    cursor: pointer;
    padding: 5px 20px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    border-radius: 30px;
}
.strip_list ul li:last-child a:hover {
    background-color: #0086ad;
    color: #fff !important;
}
@media (max-width: 991px) {
    .strip_list ul li:first-child {
        /* For less than 991px */
        display: none;
    }
}

.rating i {
    color: #ddd;
    font-size: 13px;
    font-size: 0.8125rem;
}
.rating i.voted {
    color: #ffc107;
}
.rating small {
    margin-bottom: 0;
    display: inline-block;
    font-size: 12px;
    font-size: 0.75rem;
}
.rating small a {
    color: #999;
    text-decoration: underline;
}

/*-------- 2.3 Map listing --------*/
.infoBox > img {
    position: absolute !important;
    right: 60px !important;
    top: 10px !important;
    z-index: 9999999;
}

.marker_info {
    width: 200px;
    margin: -240px;
    padding: 20px 15px 15px 15px;
    text-align: center;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.9);
    position: relative;
    z-index: 99999;
    font-family: 'Poppins', Helvetica, sans-serif;
}
.marker_info figure {
    width: 60px;
    height: 60px;
    overflow: hidden;
    margin: auto;
    margin-bottom: 10px;
    position: relative;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
}
.marker_info figure img {
    width: auto;
    height: 80px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.marker_info h3 {
    font-size: 16px;
    font-size: 1rem;
    margin: 0 0 10px 0;
}
.marker_info h3 a {
    color: #0086ad;
}
.marker_info h3 a:hover {
    color: #333;
}
.marker_info small {
    font-size: 10px;
    font-size: 0.625rem;
    text-transform: uppercase;
    color: #999;
    font-weight: 600;
}
.marker_info span {
    display: block;
    padding: 0;
    font-size: 12px;
    font-size: 0.75rem;
    margin-bottom: 10px;
}
.marker_info:after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-right-color: rgba(255, 255, 255, 0.9);
    border-width: 10px;
    margin-top: -10px;
}

.btn_infobox_get_directions:before,
.btn_infobox_phone:before {
    font-family: 'ElegantIcons';
    font-size: 14px;
    font-size: 0.875rem;
    cursor: pointer;
    position: relative;
    left: 0;
    top: 2px;
    color: #0086ad;
    margin-right: 8px;
}

.btn_infobox_get_directions {
    border: none;
    display: block;
    font-weight: 500;
    color: #555;
    background: none;
    cursor: pointer;
    outline: none;
    font-size: 12px;
    font-size: 0.75rem;
}
.btn_infobox_get_directions:before {
    content: '\e081';
}

.btn_infobox_phone {
    border: none;
    display: block;
    font-weight: 500;
    color: #555;
    background: none;
    cursor: pointer;
    font-size: 12px;
    font-size: 0.75rem;
    outline: none;
}
.btn_infobox_phone:before {
    content: '\e090';
}

.full-height {
    height: 600px;
}
@media (max-width: 991px) {
    .full-height {
        height: auto;
    }
}

.row-height {
    height: 100%;
    min-height: 100%;
}
@media (max-width: 991px) {
    .row-height {
        /* For less than 991px */
        height: auto;
    }
}

.map-right {
    min-height: 100%;
    padding: 0px;
}
@media (max-width: 991px) {
    .map-right {
        /* For less than 991px */
        height: 400px;
    }
}

#map_listing {
    height: 100%;
    width: 100%;
}
#map_listing.normal_list {
    height: 495px;
    width: auto;
    border: 3px solid #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 25px;
}

.content-left {
    height: 100%;
    min-height: 100%;
    overflow-y: scroll;
    padding-top: 15px;
    background-color: #f5f8fa;
}

.search_bar {
    margin-bottom: 15px;
    position: relative;
}
.search_bar input[type='submit'] {
    position: absolute;
    right: 8px;
    top: 2px;
    text-indent: -9999px;
    border: 0;
    width: 35px;
    height: 35px;
    background: url(../img/search.svg) no-repeat center right;
    cursor: pointer;
}

.filters_listing {
    border-bottom: 1px solid #e1e8ed;
    padding: 15px 0;
}
.filters_listing.map_listing {
    border-bottom: none;
}
.filters_listing ul {
    margin: 0;
}
.filters_listing ul li {
    margin-right: 10px;
}
.filters_listing ul li h6 {
    font-size: 12px;
    font-size: 0.75rem;
    color: #999;
    margin-bottom: 5px;
}
@media (max-width: 767px) {
    .filters_listing ul li h6 {
        /* For less than 767px */
        display: none;
    }
}
.filters_listing ul li:first-child {
    float: left;
}
.filters_listing ul li:nth-child(2) {
    float: right;
    margin-right: 0;
}
@media (max-width: 767px) {
    .filters_listing ul li:nth-child(2) {
        /* For less than 767px */
        float: left;
    }
}
.filters_listing ul li:nth-child(3) {
    float: right;
}
@media (max-width: 767px) {
    .filters_listing ul li:nth-child(3) {
        /* For less than 767px */
        float: left;
        clear: both;
    }
}

.layout_view {
    background-color: #fff;
    border: 1px solid #e1e8ed;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding: 6px 8px 5px 8px;
    margin-bottom: 5px;
}
.layout_view a {
    color: #4b9e63;
}
.layout_view a.active {
    color: #ccc;
    cursor: default;
}
.layout_view a:hover {
    color: #0086ad;
}

.switch-field {
    overflow: hidden;
}
.switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}
.switch-field input:checked + label {
    background-color: #4b9e63;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #fff;
}
.switch-field label {
    display: inline-block;
    min-width: 70px;
    background-color: #fff;
    color: #4b9e63;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    font-size: 0.75rem;
    text-align: center;
    text-shadow: none;
    padding: 10px 15px 8px 15px;
    line-height: 1;
    border: 1px solid #e1e8ed;
    border-right: 0;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.switch-field label:first-of-type {
    border-radius: 3px 0 0 3px;
}
.switch-field label:last-of-type {
    border-radius: 0 3px 3px 0;
    border: 1px solid #e1e8ed;
}
.switch-field label:hover {
    cursor: pointer;
    color: #0086ad;
}

.sbHolder {
    background-color: #fff;
    border: 1px solid #e1e8ed;
    height: 32px;
    position: relative;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    min-width: 160px;
}

.sbSelector {
    display: block;
    height: 32px;
    left: 0;
    line-height: 32px;
    outline: none;
    overflow: hidden;
    position: absolute;
    text-indent: 10px;
    top: 0;
    width: 100%;
}

.sbSelector:link,
.sbSelector:visited,
.sbSelector:hover {
    color: #555;
    outline: none;
    text-decoration: none;
    color: #4b9e63;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    font-size: 0.75rem;
}

.sbToggle {
    position: absolute;
    display: block;
    height: 32px;
    outline: none;
    right: 0;
    top: 0;
    width: 35px;
    text-align: center;
    line-height: 32px;
}
.sbToggle:after {
    font-family: 'ElegantIcons';
    font-size: 14px;
    content: '\43';
    color: #4b9e63;
    text-align: center;
}

.sbHolderDisabled {
    background-color: #ededed;
    border: solid 1px #ededed;
}

.sbOptions {
    background-color: #fff;
    border: solid 1px #ededed;
    list-style: none;
    left: -1px;
    right: -1px;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 40px;
    z-index: 10;
    font-size: 12px;
    font-size: 0.75rem;
}

.sbOptions li {
    padding: 0 7px;
    float: none !important;
    border-bottom: solid 1px #ededed;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: 100%;
}

.sbOptions li:last-child {
    border-bottom: none;
    float: none !important;
    margin: 0;
    padding-left: 10px;
}

.sbOptions li:hover {
    color: #0086ad;
}

.sbOptions a {
    display: block;
    outline: none;
    padding: 7px 0 7px 3px;
    text-transform: capitalize;
}

.sbOptions a:link,
.sbOptions a:visited {
    color: #555;
    text-decoration: none;
}

.sbOptions a:hover,
.sbOptions a:focus,
.sbOptions a.sbFocus {
    color: #0086ad;
}

.sbOptions li.last a {
    border-bottom: none;
}

.sbOptions .sbDisabled {
    border-bottom: dotted 1px #fff;
    color: #999;
    display: block;
    padding: 7px 0 7px 3px;
}

.sbOptions .sbGroup {
    border-bottom: dotted 1px #fff;
    color: #555;
    display: block;
    font-weight: bold;
    padding: 7px 0 7px 3px;
}

.sbOptions .sbSub {
    padding-left: 17px;
}

/* Cluster styles */
.cluster img {
    display: none !important;
}

.cluster-visible {
    text-align: center;
    font-size: 15px !important;
    color: #fff !important;
    font-weight: 500;
    border-radius: 50%;
    width: 36px !important;
    height: 36px !important;
    line-height: 36px !important;
    background-color: #4b9e63 !important;
    background-image: none !important;
}

.cluster-visible:before {
    border: 7px solid #4b9e63;
    opacity: 0.2;
    box-shadow: inset 0 0 0 4px #4b9e63;
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: cluster-animation 2.5s infinite;
}

@keyframes cluster-animation {
    0%,
    100% {
        transform: scale(1.3) rotate(0deg);
    }
    50% {
        transform: scale(1.5) rotate(90deg);
    }
}
/*-------- 2.4 Detail page  --------*/
#breadcrumb {
    background-color: #4b9e63;
    padding: 25px 0;
    line-height: 1;
    color: #fff;
}
@media (max-width: 767px) {
    #breadcrumb {
        /* For less than 767px */
        padding: 15px 0;
    }
}
#breadcrumb ul {
    margin: 0;
    font-size: 13px;
    font-size: 0.8125rem;
}
#breadcrumb ul li {
    display: inline-block;
    padding-right: 8px;
    margin-right: 3px;
    position: relative;
}
#breadcrumb ul li a {
    color: #fff;
}
#breadcrumb ul li a:hover {
    color: #74d1c6;
}
#breadcrumb ul li:after {
    content: '\e9ee';
    font-family: 'fontello';
    position: absolute;
    right: 0;
    top: 0;
}
#breadcrumb ul li:last-child {
    opacity: 0.7;
}
#breadcrumb ul li:last-child:after {
    content: '';
    margin-right: 0;
}

.tabs_styled_2 .nav-tabs .nav-item.show .nav-link,
.tabs_styled_2 .nav-tabs .nav-link.active {
    color: #333;
    background-color: #fff;
    border-color: #e1e8ed;
    border-bottom: 1px solid transparent;
}
@media (max-width: 767px) {
    .tabs_styled_2 .nav-tabs .nav-item.show .nav-link,
    .tabs_styled_2 .nav-tabs .nav-link.active {
        /* For less than 767px */
        border: 1px solid transparent;
    }
}
.tabs_styled_2 .nav-tabs .nav-link:focus,
.tabs_styled_2 .nav-tabs .nav-link.active:hover {
    border-color: #e1e8ed;
    border-bottom: 1px solid transparent;
}
@media (max-width: 767px) {
    .tabs_styled_2 .nav-tabs .nav-link:focus,
    .tabs_styled_2 .nav-tabs .nav-link.active:hover {
        /* For less than 767px */
        border: 1px solid transparent;
    }
}
.tabs_styled_2 .nav-tabs .nav-link:hover {
    border-color: transparent;
}
@media (max-width: 767px) {
    .tabs_styled_2 .nav-item {
        /* For less than 767px */
        display: block;
        width: 100%;
        margin-bottom: 5px;
        border: 1px solid #e1e8ed;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        border-radius: 5px;
    }
}
.tabs_styled_2 .nav-tabs .nav-link {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-size: 16px;
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    margin-right: 5px;
}
@media (max-width: 767px) {
    .tabs_styled_2 .nav-tabs .nav-link {
        /* For less than 767px */
        margin-right: 0;
        padding: 0.5rem;
    }
}
.tabs_styled_2 .nav-tabs {
    border-bottom: 1px solid #e1e8ed;
}
.tabs_styled_2 .tab-pane {
    background-color: #fff;
    padding: 30px 30px 20px 30px;
    border: 1px solid #e1e8ed;
    border-top: none;
}
.tabs_styled_2 .tab-pane hr {
    margin: 30px -30px 30px -30px;
    border-color: #ededed;
}

.indent_title_in {
    position: relative;
    padding-left: 60px;
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .indent_title_in {
        /* For less than 767px */
        padding-left: 0;
    }
}
.indent_title_in i {
    font-size: 40px;
    position: absolute;
    left: 0;
    color: #4b9e63;
    top: 0;
}
@media (max-width: 767px) {
    .indent_title_in i {
        /* For less than 767px */
        position: static;
    }
}
.indent_title_in h3 {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 21px;
}
.indent_title_in p {
    color: #999;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-size: 0.875rem;
}

.wrapper_indent {
    padding-left: 60px;
}
@media (max-width: 767px) {
    .wrapper_indent {
        /* For less than 767px */
        padding-left: 0;
    }
}

ul.list_edu {
    margin-bottom: 0;
    line-height: 1.8;
}
ul.list_edu li:before {
    font-family: 'fontello';
    content: '\ea3e';
    margin-right: 8px;
    color: #ccc;
}
ul.list_edu li strong {
    font-weight: 500;
}

#review_summary {
    text-align: center;
    background-color: #4b9e63;
    color: #fff;
    padding: 20px 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
}
@media (max-width: 991px) {
    #review_summary {
        /* For less than 991px */
        margin-bottom: 15px;
    }
}
#review_summary strong {
    font-size: 3rem;
    line-height: 1;
}

.reviews-container .progress {
    margin-bottom: 10px;
}
.reviews-container .progress-bar {
    background-color: #74d1c6;
}
.reviews-container .review-box {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 25px;
    min-height: 100px;
}
.reviews-container .rev-content {
    position: relative;
    width: 80%;
    padding: 25px 25px 0 25px;
    border: 1px solid #ededed;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
}
.image_avatars_review {
    margin-right: 5px;
    flex-grow: 1;
    
}
.reviews-container .rev-thumb {
    position: absolute;
    left: 0px !important;
    top: 0px;
    width: 80px;
    height: 80px;
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
}
.reviews-container .rev-thumb img {
    width: 80px;
    height: auto;
}
@media (max-width: 767px) {
    .reviews-container .rev-thumb {
        /* For less than 767px */
        position: static;
        margin-bottom: 10px;
    }
}
.reviews-container .rev-info {
    font-size: 0.75rem;
    font-style: italic;
    color: #777;
    margin-bottom: 10px;
}

#calendar {
    padding: 0;
    margin-bottom: 30px;
}
#calendar .datepicker.datepicker-inline,
#calendar .datepicker.datepicker-inline table {
    width: 100%;
}

.datepicker thead tr th.prev:hover,
.datepicker thead tr th.next:hover {
    color: #0086ad;
}

.datepicker .disabled.disabled-date.day,
.datepicker .disabled.disabled-date.day:hover {
    background-color: #eb525b !important;
    color: #fff !important;
}

.datepicker .day:hover {
    background: #8ec549 !important;
    color: #fff;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.datepicker .active.day:hover {
    background: #333 !important;
    color: #fff;
}

ul.legend li {
    display: inline-block;
    position: relative;
    margin-right: 15px;
    padding-left: 30px;
}
ul.legend li strong {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: -2px;
}
ul.legend li:first-child strong {
    background-color: #8ec549;
}
ul.legend li:last-child strong {
    background-color: #eb525b;
}

ul.time_select.version_2 li {
    float: left;
    width: 50%;
}
ul.time_select li input[type='radio'] {
    display: none;
    cursor: pointer;
}
ul.time_select li input[type='radio']:checked + label {
    background-color: #333;
    color: #fff;
}
ul.time_select li label {
    display: inline-block;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #f8f8f8;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding: 8px 10px 6px 10px;
    line-height: 1;
    min-width: 100px;
    margin: 5px;
    text-align: center;
    cursor: pointer;
}
ul.time_select li label:hover {
    background-color: #0086ad;
    color: #fff;
}

ul.treatments {
    margin-bottom: 45px;
}
ul.treatments li {
    border-bottom: 1px dotted #ddd;
    width: 45%;
    float: left;
    margin: 15px;
}
@media (max-width: 991px) {
    ul.treatments li {
        /* For less than 991px */
        width: auto;
        float: none;
    }
}

input[type='checkbox'].css-checkbox {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

input[type='checkbox'].css-checkbox + label.css-label {
    padding-left: 35px;
    height: 25px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    cursor: pointer;
    padding-top: 3px;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

input[type='checkbox'].css-checkbox:hover + label.css-label {
    background-position: 0 -28px;
}

input[type='checkbox'].css-checkbox:checked + label.css-label {
    background-position: 0 -56px;
}

label.css-label {
    background-image: url(../img/checkbox.png);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
    display: block;
}
label.css-label strong {
    float: right;
}

#sidebar_detail {
    position: relative;
    top: -240px;
}
#sidebar_detail #map {
    width: 100%;
    height: 350px;
    text-align: center;
    background-color: #fff;
    border: 5px solid #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 25px;
}
#sidebar_detail h4 {
    text-transform: uppercase;
    color: #6d7b84;
    font-size: 18px;
    font-size: 1.125rem;
}

.box_profile {
    background-color: #fff;
    text-align: center;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #e1e8ed;
    padding: 0 25px 25px 25px;
    margin-bottom: 35px;
}
.figureGrid {
    margin: 0 !important;
}
.box_profile figure {
    margin: -1px -26px 25px -26px;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
}
@media (max-width: 991px) {
    .box_profile figure {
        /* For less than 991px */
        width: 100px;
        height: 100px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        margin: 25px auto;
    }
}
.box_profile .rating {
    margin-bottom: 20px;
    display: inline-block;
}
.box_profile small {
    text-transform: uppercase;
    color: #999;
    font-weight: 600;
}
.box_profile h1 {
    font-size: 21px;
    font-size: 1.3125rem;
}
.box_profile p {
    color: #999;
}
.box_profile ul.statistic {
    padding-bottom: 5px;
}
.box_profile ul.statistic li {
    display: inline-block;
    background-color: #74d1c6;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    line-height: normal;
    color: #fff;
    padding: 3px 10px;
    font-weight: 600;
    font-size: 12px;
    font-size: 0.75rem;
    min-width: 95px;
}
.box_profile ul.contacts {
    text-align: left;
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    padding: 25px;
    margin: 0 -25px 25px -25px;
}
@media (max-width: 991px) {
    .box_profile ul.contacts {
        /* For less than 991px */
        text-align: center;
    }
}
.box_profile ul.contacts li {
    margin-bottom: 15px;
}
.box_profile ul.contacts li h6 {
    font-size: 14px;
    font-size: 0.875rem;
    margin-bottom: 3px;
}
.box_profile ul.contacts li:last-child {
    margin-bottom: 0;
}

/*Version 3*/
.profile {
    padding-top: 20px;
}
@media (max-width: 767px) {
    .profile {
        /* For less than 767px */
        padding-top: 0;
    }
}
.profile .rating {
    margin-bottom: 15px;
    display: inline-block;
}
.profile small {
    text-transform: uppercase;
    color: #999;
    font-weight: 600;
}
.profile h1 {
    font-size: 24px;
    font-size: 1.5rem;
}
.profile p {
    color: #999;
}
.profile ul.statistic {
    padding-bottom: 5px;
    margin-bottom: 15px;
}
.profile ul.statistic li {
    display: inline-block;
    background-color: #74d1c6;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    line-height: 1;
    color: #fff;
    padding: 8px 10px 5px 10px;
    font-weight: 600;
    font-size: 12px;
    font-size: 0.75rem;
    min-width: 95px;
    text-align: center;
}
.profile ul.contacts {
    margin: 25px 0 0 0;
}
.profile ul.contacts li {
    margin-bottom: 15px;
}
.profile ul.contacts li h6 {
    font-size: 14px;
    font-size: 0.875rem;
    margin-bottom: 3px;
}
.profile ul.contacts li:last-child {
    margin-bottom: 0;
}

.booking .title {
    background-color: #4b9e63;
    color: #fff;
    margin: -30px -30px 30px -30px;
    padding: 20px 30px;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}
.booking .title h3 {
    font-size: 28px;
    font-size: 1.75rem;
    margin: 0;
    color: #fff;
}
.booking .title small {
    font-size: 13px;
    font-size: 0.8125rem;
}
.booking hr {
    margin-top: 15px !important;
}
.booking ul.treatments {
    margin: 15px 0 0 0;
}
.booking ul.treatments li {
    border-top: 1px dotted #ddd;
    border-bottom: none;
    width: 100%;
    margin: 0;
    padding: 12px 0 5px 0;
}
@media (max-width: 991px) {
    .booking ul.treatments li {
        /* For less than 991px */
        width: auto;
        float: none;
    }
}

#secondary_nav {
    background-color: #373869;
    background-color: rgba(63, 64, 121, 0.05);
    background-color: #4b9e63;
    position: relative;
    bottom: -3px;
    border-bottom: none;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
    padding: 15px;
}
#secondary_nav.is_stuck {
    z-index: 99999999 !important;
    width: 100% !important;
    bottom: inherit;
    left: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
}
#secondary_nav ul {
    margin: 0;
}
#secondary_nav ul li {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    font-size: 1rem;
    margin-right: 25px;
}
@media (max-width: 767px) {
    #secondary_nav ul li {
        /* For less than 767px */
        margin-right: 15px;
    }
}
#secondary_nav ul li:last-child {
    display: none;
    margin-right: 0;
}
@media (max-width: 991px) {
    #secondary_nav ul li:last-child {
        /* For less than 991px */
        display: inline-block;
    }
}
#secondary_nav ul li a {
    color: #74d1c6;
}
#secondary_nav ul li a:hover {
    color: #fff;
}
#secondary_nav ul li a.active {
    color: #fff;
}

#section_1,
#section_2,
#section_3 {
    padding-bottom: 30px;
}

/*-------- 2.5 Login/register --------*/
#login {
    width: 400px;
    margin: 60px auto;
    color: #fff;
}
@media (max-width: 767px) {
    #login {
        /* For less than 767px */
        width: 100%;
        margin: 30px 0;
    }
}
#login h1 {
    font-size: 28px;
    font-size: 1.75rem;
    text-align: center;
    margin: 0 0 30px 0;
    color: #fff;
}
@media (max-width: 767px) {
    #login h1 {
        /* For less than 767px */
        font-size: 21px;
        font-size: 1.3125rem;
    }
}

.divider {
    text-align: center;
    height: 1px;
    margin: 30px 0;
    background-color: #e1e8ed;
}
.divider span {
    position: relative;
    top: -20px;
    background-color: #fff;
    display: inline-block;
    padding: 10px;
    font-style: italic;
}

.link_bright a {
    color: #0086ad;
}
.link_bright a:hover {
    color: #fff02a;
}

#login-2 {
    width: 650px;
    margin: 60px auto;
    color: #fff;
}
#login-2 .box_form {
    padding: 30px 0 0 0;
}
@media (max-width: 767px) {
    #login-2 .box_form {
        /* For less than 767px */
        padding: 20px 20px 0 20px;
    }
}
#login-2 .forgot {
    display: inline-block;
    margin-top: 5px;
}
@media (max-width: 767px) {
    #login-2 {
        /* For less than 767px */
        width: 100%;
        margin: 0;
    }
}
#login-2 h1 {
    font-size: 28px;
    font-size: 1.75rem;
    text-align: center;
    margin: 0 0 30px 0;
    color: #fff;
}

.box_login {
    width: 50%;
    float: left;
    border-right: 1px solid #e1e8ed;
    padding: 15px 25px;
}
@media (max-width: 767px) {
    .box_login {
        /* For less than 767px */
        width: 100%;
        border-right: 0;
        padding: 0;
    }
}
.box_login.last {
    border-right: 0;
}

a.social_bt {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    color: #fff;
    min-width: 200px;
    margin-bottom: 15px;
    display: block;
    padding: 12px;
    line-height: 1;
    position: relative;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
a.social_bt:hover {
    -webkit-filter: brightness(115%);
    filter: brightness(115%);
}
a.social_bt.facebook:before,
a.social_bt.google:before,
a.social_bt.linkedin:before {
    font-family: 'ElegantIcons';
    position: absolute;
    left: 12px;
    top: 10px;
    font-size: 1rem;
    color: #fff;
}
a.social_bt.facebook {
    background-color: #3b5998;
}
a.social_bt.facebook:before {
    content: '\e093';
}
a.social_bt.google {
    background-color: #dc4e41;
}
a.social_bt.google:before {
    content: '\e096';
    top: 12px;
}
a.social_bt.linkedin {
    background-color: #0077b5;
}
a.social_bt.linkedin:before {
    content: '\e09d';
}

#register {
    margin: 60px auto;
    color: #fff;
}
#register h1 {
    font-size: 28px;
    font-size: 1.75rem;
    text-align: center;
    margin: 0 0 30px 0;
    color: #fff;
}

.checkbox_2 {
    position: relative;
    display: block;
}
.checkbox_2:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 2px solid #e1e8ed;
    background: transparent;
}
.checkbox_2 input[type='checkbox']:focus + label:before {
    background-color: #e53c78;
    background-color: rgba(231, 78, 132, 0.2);
}
.checkbox_2 label:after {
    content: '';
    position: absolute;
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    opacity: 0;
    background: #0086ad;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.checkbox_2 input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
}
.checkbox_2 input[type='checkbox']:checked + label:after {
    opacity: 1;
}

.checkbox-holder span {
    position: relative;
    display: inline-block;
    margin: 2px 0 0 30px;
}

/*Password strength */
#pass-info {
    width: 100%;
    margin-bottom: 15px;
    color: #555;
    text-align: center;
    font-size: 12px;
    font-size: 0.75rem;
    padding: 5px 0 3px 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
}
#pass-info.weakpass {
    border: 1px solid #ff9191;
    background: #ffc7c7;
    color: #94546e;
}
#pass-info.stillweakpass {
    border: 1px solid #fbb;
    background: #fdd;
    color: #945870;
}
#pass-info.goodpass {
    border: 1px solid #c4eec8;
    background: #e4ffe4;
    color: #51926e;
}
#pass-info.strongpass {
    border: 1px solid #6ed66e;
    background: #79f079;
    color: #348f34;
}
#pass-info.vrystrongpass {
    border: 1px solid #379137;
    background: #48b448;
    color: #cdffcd;
}

#hero_register {
    background: #4b9e63 url(../img/hero_bg_1.svg) repeat fixed;
    color: #fff;
}
#hero_register h1 {
    color: #fff;
}
@media (max-width: 767px) {
    #hero_register h1 {
        /* For less than 767px */
        font-size: 32px;
        font-size: 2rem;
    }
}

.box_feat_2 {
    position: relative;
    padding-left: 60px;
}
.box_feat_2 h3 {
    font-size: 18px;
    font-size: 1.125rem;
    color: #fff02a;
}
.box_feat_2 i {
    font-size: 46px;
    font-size: 2.875rem;
    margin-bottom: 10px;
    color: #74d1c6;
    position: absolute;
    left: 0;
    top: 0;
}

/*-------- 2.6 404 page --------*/
#error_page {
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #4b9e63 url(../img/hero_bg_1.svg) repeat fixed;
    color: #fff;
}
#error_page h2 {
    font-size: 140px;
    font-size: 8.75rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1;
}
#error_page h2 i {
    color: #fff02a;
}
@media (max-width: 767px) {
    #error_page h2 {
        /* For less than 767px */
        font-size: 72px;
        font-size: 4.5rem;
    }
}
#error_page p {
    font-size: 21px;
    font-size: 1.3125rem;
}
@media (max-width: 767px) {
    #error_page {
        /* For less than 767px */
        padding: 0;
        height: 450px;
    }
}

.search_bar_error {
    position: relative;
    margin-bottom: 60px;
}
.search_bar_error input[type='text'] {
    border: 0;
    height: 50px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding-left: 15px;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.35);
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.35);
}
.search_bar_error input[type='submit'] {
    position: absolute;
    right: 0;
    color: #fff;
    font-weight: 600;
    top: 0;
    border: 0;
    padding: 0 25px;
    height: 50px;
    cursor: pointer;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    background-color: #74d1c6;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.search_bar_error input[type='submit']:hover {
    background-color: #0086ad;
}

/*-------- 2.7 Contact page --------*/
#contact_info {
    margin: 35px 0 0 0;
}
@media (max-width: 767px) {
    #contact_info {
        /* For less than 767px */
        margin: 0 0 35px 0;
    }
}
#contact_info h3 {
    font-size: 18px;
    font-size: 1.125rem;
}
#contact_info h4 {
    font-size: 14px;
    font-size: 0.875rem;
}
#contact_info ul li {
    margin-bottom: 15px;
}
#contact_info ul li strong {
    display: block;
    font-weight: 500;
}

#map_contact {
    width: 100%;
    height: 500px;
}
@media (max-width: 767px) {
    #map_contact {
        /* For less than 767px */
        height: 350px;
    }
}

.error_message {
    font-weight: 500;
    color: #f26c4f;
    display: inline-block;
    margin-bottom: 10px;
}

/*-------- 2.8 Booking page/confirm --------*/
.form_title {
    position: relative;
    padding-left: 55px;
    margin-bottom: 10px;
}
.form_title h3 {
    margin: 0;
    padding: 0;
    font-size: 21px;
    font-size: 1.3125rem;
}
.form_title h3 strong {
    background-color: #0086ad;
    text-align: center;
    width: 40px;
    height: 40px;
    display: inline-block;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    color: #fff;
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 42px;
    text-align: center;
    position: absolute;
    left: 0;
    top: -5px;
}
.form_title p {
    color: #999;
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 14px;
}

.cards {
    margin-top: 28px;
}
@media (max-width: 991px) {
    .cards {
        /* For less than 991px */
        display: none;
    }
}

.step {
    padding: 20px 0 0 55px;
    margin: 0 0 0 0;
}
@media (max-width: 991px) {
    .step {
        /* For less than 991px */
        padding-left: 0;
    }
}

.box_general_3.cart label {
    font-weight: 500;
}

.message {
    background-color: #f8f8f8;
    padding: 15px;
    margin-bottom: 30px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
}
.message p {
    margin: 0;
}

.summary ul {
    margin: 0 0 15px 0;
    padding: 0;
}
.summary ul li {
    margin-bottom: 10px;
}
.summary ul li strong {
    font-weight: 500;
}

ul.treatments.checkout {
    margin: 0;
    padding: 0;
}
ul.treatments.checkout li {
    margin-bottom: 8px;
}
ul.treatments.checkout li strong {
    font-weight: 500;
}
ul.treatments.checkout li.total {
    text-transform: uppercase;
    color: #4b9e63;
    font-weight: 500;
    font-size: 18px;
    font-size: 1.125rem;
}

#confirm {
    text-align: center;
}

@-webkit-keyframes checkmark {
    0% {
        stroke-dashoffset: 50px;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
@-ms-keyframes checkmark {
    0% {
        stroke-dashoffset: 50px;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes checkmark {
    0% {
        stroke-dashoffset: 50px;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
@-webkit-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px;
    }
    100% {
        stroke-dashoffset: 480px;
    }
}
@-ms-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px;
    }
    100% {
        stroke-dashoffset: 480px;
    }
}
@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px;
    }
    100% {
        stroke-dashoffset: 480px;
    }
}
.inlinesvg .svg svg {
    display: inline;
}

.icon--order-success svg path {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards;
}

.icon--order-success svg circle {
    -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
    animation: checkmark-circle 0.6s ease-in-out backwards;
}

/*-------- 2.9 Pricing page --------*/
.pricing_head {
    padding: 100px 0 120px 0;
    text-align: center;
    color: #fff;
}
@media (max-width: 767px) {
    .pricing_head {
        /* For less than 767px */
        padding: 80px 0;
    }
}
.pricing_head h1 {
    color: #fff;
    font-weight: 500;
    font-size: 38px;
    font-size: 2.375rem;
}
.pricing_head p {
    font-weight: 300;
    font-size: 21px;
    font-size: 1.3125rem;
}

.margin_tabs {
    margin-top: -97px;
}
@media (max-width: 991px) {
    .margin_tabs {
        /* For less than 991px */
        margin-top: -70px;
    }
}
@media (max-width: 767px) {
    .margin_tabs {
        /* For less than 767px */
        margin-top: -50px;
    }
}

.tabs {
    position: relative;
    width: 100%;
    overflow: visible;
    padding-bottom: 35px;
}
.tabs nav {
    text-align: center;
    overflow: hidden;
}
.tabs nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    width: 100%;
    text-align: center;
}
.tabs nav ul li {
    display: block;
    display: inline-block;
    background-color: #f5f8fa;
    border-radius: 10px;
    width: 30%;
    text-align: left;
    line-height: 1.3;
    margin: 2px;
    cursor: pointer;
}
.tabs nav ul li:hover {
    background-color: wheat;
    opacity: 0.5;
    cursor: pointer;
}
.tabsactive {
    background-color: wheat;
    opacity: 0.5;
    cursor: pointer;
    border-bottom: 5px solid #54b06e;
    border-radius: 10px;
}
@media (max-width: 480px) {
    .tabs nav ul li {
        margin: 3px;
    }
    .tabs nav ul {
        flex-wrap: wrap;
    }
}
@media (max-width: 991px) {
    .tabs nav ul li {
        text-align: center;
    }
}
@media (max-width: 480px) {
    .tabs nav ul  {
        justify-content: space-around;
     
    }
    .tabs nav ul li {
    margin-bottom: 1.5em;
        width: 34%;
    }
}
.tabs nav ul li div {
    padding: 5px 5px 5px 50px;
    color: #555;
    font-size: 21px;
    font-size: 1.3125rem;
    white-space: nowrap;
    position: relative;
    font-weight: 500;
}
@media (max-width: 991px) {
    .tabs nav ul li div {
        padding: 2px;
        font-size: 10px !important;
       
    }
    .img-tabs-reg {
        width: 32px !important;
        max-width: 32px !important;
        min-width: 32px !important;
        height: 32px !important;
        max-height: 32px !important;
        min-height: 32px !important;
        margin: 1px !important;
    }
}
/* Content */
.content section {
    padding: 60px 0 0 0;
    display: none;
    max-width: 1230px;
    margin: 0 auto;
}
.content section:before,
.content section:after {
    content: '';
    display: table;
}
.content section.content-current {
    display: block;
}

.content section:after {
    clear: both;
}

.no-js .content section {
    display: block;
    padding-bottom: 2em;
    border-bottom: 1px solid #e2e2e2;
}

.box_pricing {
    padding: 30px;
    margin-bottom: 30px;
    border: 1px solid #ededed;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    position: relative;
}
@media (max-width: 767px) {
    .box_pricing {
        /* For less than 767px */
        padding: 15px;
    }
}
.box_pricing hr {
    margin: 25px -30px;
    border-color: #ededed;
}
@media (max-width: 767px) {
    .box_pricing hr {
        /* For less than 767px */
        margin: 25px -15px;
    }
}
.box_pricing ul {
    list-style: disc;
    padding: 0 0 0 20px;
    margin: 0 0 30px 0;
}
.box_pricing a {
    background-color: #4b9e63;
    display: block;
    color: #fff;
    padding: 10px;
    font-weight: 500;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
}
.box_pricing a:hover {
    background-color: #0086ad;
    color: #fff;
}
.box_pricing .price {
    text-align: center;
    font-weight: 500;
    font-size: 36px;
    font-size: 2.25rem;
    margin-bottom: 20px;
    color: #4b9e63;
}
.box_pricing .price sup {
    font-size: 16px;
    font-size: 1rem;
}
.box_pricing .price em {
    font-size: 16px;
    font-size: 1rem;
    color: #555;
    font-weight: 400;
}

.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}
.ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #222;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    position: absolute;
    top: 19px;
    right: -21px;
}
.ribbon span.top_selling {
    background: #00dfaa;
}
.ribbon span.top_selling::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #008a69;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #008a69;
}
.ribbon span.top_selling::after {
    content: '';
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #008a69;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #008a69;
}

/*-------- 2.10 Submit review --------*/
.box_general_3.write_review h1 {
    font-weight: 500;
    font-size: 28px;
    font-size: 1.75rem;
    margin-bottom: 20px;
}
.box_general_3.write_review label {
    font-weight: 500;
}

.rating {
    display: inline-block;
    position: relative;
}

.rating-input {
    display: none;
}

.rating:hover .rating-star:hover,
.rating:hover .rating-star:hover ~ .rating-star,
.rating-input:checked ~ .rating-star {
    background-position: 0 0;
}

.rating-star,
.rating:hover .rating-star {
    cursor: pointer;
    float: right;
    display: block;
    margin-right: 3px;
    width: 37px;
    height: 37px;
    background: url(../img/stars.svg) 0 -38px;
}

.fileupload {
    position: relative;
    margin-bottom: 5px;
}

.img-tabs-reg {
    width: 48px;
    max-width: 48px;
    min-width: 48px;
    height: 48px;
    max-height: 48px;
    min-height: 48px;
    margin: 5px;
}

input[type='file'] {
    background-color: #fff;
    padding: 5px;
    height: auto;
    color: #999;
    border-radius: 3px;
}

input[type='file']::-webkit-file-upload-button,
input[type='file'].invalid::-webkit-file-upload-button,
input[type='file'].valid::-webkit-file-upload-button {
    color: #fff;
    font-size: 13px;
    border: 0;
    border-radius: 3px;
    padding: 8px 10px 8px 38px;
    background: #4b9e63 url(../img/camera.svg) 8px center no-repeat;
    outline: none;
}

/*-------- 2.11 Home with map --------*/
.hero_map {
    width: 100%;
    height: 600px;
    position: relative;
}
@media (max-width: 767px) {
    .hero_map {
        /* For less than 767px */
        height: 550px;
    }
}
.hero_map .search_wp {
    width: 100%;
    position: absolute;
    bottom: 15px;
}
@media (max-width: 991px) {
    .hero_map .search_wp {
        /* For less than 991px */
        padding: 0 10px;
    }
}
@media (max-width: 767px) {
    .hero_map .search_wp {
        /* For less than 767px */
        position: relative;
        bottom: 140px;
    }
}
.hero_map .search_wp #custom-search-input ul li label {
    background-color: #4b9e63;
    color: #fff;
}

.cta_subscribe h3 {
    color: #fff;
    font-size: 26px;
    font-size: 1.625rem;
    font-weight: 400;
    letter-spacing: -1px;
    text-transform: uppercase;
}
.cta_subscribe p {
    font-size: 16px;
    font-size: 1rem;
}
.block_img {
    min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
}
.block_img_rm {
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
}
.block_img_po {
    min-width: 140px;
    max-width: 140px;
    min-height: 140px;
    max-height: 140px;
}
.nice_arabic {
    font-family: 'Cairo', sans-serif;
    min-width: 250px;
}
.nice_latin {
    font-family: 'Cairo', sans-serif;
    min-width: 250px;
}
.maroc_header {
    display: flex !important;
    flex-direction: column !important;
    font-family: 'Cairo', sans-serif;
    font-size: 0.5rem;
    min-width: 80px;
    align-content: center !important;
    align-items: center !important;
    align-self: center !important;
    justify-content: center !important;
    text-align: center !important;
}
.carousel .slide {
    background: transparent !important;
}
.slider_images {
    display: flex !important;
    flex-direction: column !important;
    align-self: center !important;
    align-items: center !important;
    align-content: center !important;
    justify-content: center !important;
    text-align: center !important;
    margin-right: 100px !important;
    margin-left: 100px !important;
}
.slick-list {
    padding: 0px 50px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between;
    padding-right: 700px !important;
}
.cta_subscribe .block_1 {
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: #54b06e;
    color: #fff;
}
@media (max-width: 767px) {
    .cta_subscribe .block_1 {
        /* For less than 767px */
        padding: 60px 15px;
    }
}
.cta_subscribe .block_2 {
    padding: 60px;
    background-color: #65b77c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #fff;
}
@media (max-width: 767px) {
    .cta_subscribe .block_2 {
        /* For less than 767px */
        padding: 60px 15px;
    }
}

.sc-launcher {
    background-color: #4e8cff !important;
}
.cta_subscribe a.btn_1,
.cta_subscribe .btn_1 {
    border: none;
    color: #fff;
    min-width: 180px;
    background: #4e8cff;
    outline: none;
    cursor: pointer;
    display: inline-block;
    margin: 15px auto 0;
    text-decoration: none;
    padding: 15px 30px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    line-height: 1;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    font-size: 0.875rem;
}
.cta_subscribe a.btn_1:hover,
.cta_subscribe .btn_1:hover {
    background-color: #fff;
    color: #0086ad;
}

/*-------- 2.12 About --------*/
a.box_feat_about {
    text-align: center;
    background: #fff;
    padding: 40px 30px 30px 30px;
    display: block;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    -webkit-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    -webkit-box-shadow: 0px 3px 0px 0px #f0f2f4;
    -moz-box-shadow: 0px 3px 0px 0px #f0f2f4;
    box-shadow: 0px 3px 0px 0px #f0f2f4;
    color: #555;
    margin-bottom: 30px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    overflow: hidden;
}
@media (max-width: 767px) {
    a.box_feat_about {
        /* For less than 767px */
        padding: 40px 40px 40px 40px;
    }
}
a.box_feat_about:hover {
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px);
    -webkit-box-shadow: 0px 3px 0px 0px #ebedef;
    -moz-box-shadow: 0px 3px 0px 0px #ebedef;
    box-shadow: 0px 3px 0px 0px #ebedef;
}
a.box_feat_about i {
    font-size: 70px;
    font-size: 4.375rem;
    color: #4b9e63;
    margin-bottom: 10px;
}
a.box_feat_about h3 {
    font-size: 18px;
    font-size: 1.125rem;
}
.about-review {
    margin-bottom: 30px;
    border: 1px solid #e1e8ed;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    padding: 25px;
    min-height: 260px;
}

@media (max-width: 767px) {
    .about-review {
        /* For less than 767px */
        min-height: inherit;
    }
}
.about-review .user_review {
    padding-left: 90px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 40px;
}
.about-review .user_review figure {
    position: absolute;
    left: 0;
    top: 25px !important;
    width: 45px;
    height: 45px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}
.about-review .user_review figure img {
    width: auto;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.about-review .user_review h4 {
    font-size: 14px;
    font-size: 0.875rem;
    margin-bottom: 0;
    color: #777;
}
.about-review .user_review span {
    display: block;
    font-size: 12px;
    font-size: 0.75rem;
    color: #999;
    font-weight: normal;
}

.about-review .rating {
    color: #222;
    margin-bottom: 10px;
}
.about-review .rating i {
    color: #999;
}
.about-review .rating .voted {
    color: #ffc107;
}

/*============================================================================================*/
/* 3.  COMMON */
/*============================================================================================*/
/*-------- 3.1 Misc --------*/
.bg_color_1 {
    background-color: #fff;
}

.bg_color_2 {
    background: #4b9e63 url(../img/hero_bg_1.svg) repeat fixed;
}
.mapWrapped {
    width: 100vw;
    height: 100%;
}
.form-control {
    font-size: 14px;
    font-size: 0.875rem;
    min-height: 40px;
    border-color: #e1e8ed;
}

#booking .loader {
    position: absolute;
    right: 15px;
    top: 13px;
    color: #fff;
}

.box_form {
    background-color: #fff;
    padding: 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
    margin-bottom: 25px;
    color: #555;
}
.box_form label {
    font-weight: 500;
    font-size: 13px;
    font-size: 0.8125rem;
    margin-bottom: 3px;
}
.box_form .form-group {
    margin-bottom: 25px;
}

.box_general {
    background-color: #fff;
    padding: 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 15px;
    color: #555;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}
.box_general hr {
    margin-left: -30px;
    margin-right: -30px;
}

.box_general_2 {
    background-color: #fff;
    padding: 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 25px;
    border: 1px solid #e1e8ed;
}
.box_general_2 hr {
    margin-left: -30px;
    margin-right: -30px;
}

.box_general_3 {
    background-color: #fff;
    padding: 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 15px;
    border: 1px solid #e1e8ed;
}
.box_general_3 hr {
    margin: 30px -30px 30px -30px;
}

/* Checkbox style */
.container_check {
    display: block;
    position: relative;
    font-size: 14px;
    font-size: 0.875rem;
    padding-left: 30px;
    line-height: 1.4;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.container_check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.container_check input:checked ~ .checkmark {
    background-color: #4b9e63;
    border: 1px solid transparent;
}
.container_check .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #e1e8ed;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.container_check .checkmark:after {
    content: '';
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.container_check input:checked ~ .checkmark:after {
    display: block;
}

/*Show/hide password*/
.my-toggle {
    background: transparent;
    border: 0;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    color: #888;
    cursor: pointer;
    font-size: 10px;
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: bold;
    margin-right: 5px;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    text-transform: uppercase;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
}
.my-toggle:hover,
.my-toggle:focus {
    background-color: #eee;
    color: #555;
    outline: transparent;
}

.hideShowPassword-wrapper {
    width: 100% !important;
}

/*-------- 3.2 List --------*/
ul.bullets {
    line-height: 1.8;
    margin-bottom: 0;
}
ul.bullets li {
    position: relative;
    padding-left: 20px;
}
ul.bullets li:before {
    font-style: normal;
    font-weight: normal;
    font-family: 'ElegantIcons';
    font-size: 14px;
    content: '\5c';
    color: #ccc;
    position: absolute;
    left: 0;
    top: 0;
}

.box_faq {
    position: relative;
    padding-left: 50px;
}
.box_faq h4 {
    font-size: 18px;
    font-size: 1.125rem;
}
.box_faq i {
    font-size: 36px;
    font-size: 2.25rem;
    position: absolute;
    left: 0;
    top: 0;
    color: #999;
}

/*-------- 3.3 Spacing --------*/
.add_bottom_15 {
    margin-bottom: 15px;
}

.add_bottom_30 {
    margin-bottom: 30px;
}

.add_bottom_45 {
    margin-bottom: 45px;
}
@media (max-width: 767px) {
    .add_bottom_45 {
        /* For less than 767px */
        margin-bottom: 30px;
    }
}

.add_bottom_60 {
    margin-bottom: 60px;
}

.add_bottom_75 {
    margin-bottom: 75px;
}

.add_top_20 {
    margin-top: 20px;
}

.add_top_30 {
    margin-top: 30px;
}

.add_top_60 {
    margin-top: 60px;
}

.more_padding_left {
    padding-left: 40px;
}

.nomargin_top {
    margin-top: 0;
}

.nopadding {
    margin: 0 !important;
    padding: 0 !important;
}

.nomargin {
    margin: 0 !important;
}

.margin_60 {
    padding-top: 60px;
    padding-bottom: 60px;
}
@media (max-width: 767px) {
    .margin_60 {
        /* For less than 767px */
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.margin_60_35 {
    padding-top: 60px;
    padding-bottom: 35px;
}
@media (max-width: 767px) {
    .margin_60_35 {
        /* For less than 767px */
        padding-top: 30px;
        padding-bottom: 5px;
    }
}
.slide {
    min-width: 100% !important;
}
.margin_120 {
    padding-top: 120px;
    padding-bottom: 120px;
}
@media (max-width: 991px) {
    .margin_120 {
        /* For less than 991px */
        padding-top: 60px;
        padding-bottom: 35px;
    }
}

.margin_120_95 {
    padding-top: 120px;
    padding-bottom: 95px;
}
@media (max-width: 991px) {
    .margin_120_95 {
        /* For less than 991px */
        padding-top: 60px;
        padding-bottom: 35px;
    }
}
@media (max-width: 767px) {
    .margin_120_95 {
        /* For less than 767px */
        padding-top: 30px;
        padding-bottom: 5px;
    }
}

.margin_120_25 {
    padding-top: 120px;
    padding-bottom: 25px;
}
@media (max-width: 991px) {
    .margin_120_25 {
        /* For less than 991px */
        padding-top: 60px;
        padding-bottom: 5px;
    }
}
@media (max-width: 767px) {
    .margin_120_25 {
        /* For less than 767px */
        padding-top: 30px;
        padding-bottom: 2px;
    }
}

/*-------- 3.5 Community badges --------*/
.box_badges {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    background-color: #fff;
    text-align: center;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 25px 5px 25px;
    position: relative;
    margin-bottom: 30px;
}
.box_badges h3 {
    font-size: 21px;
    font-size: 1.3125rem;
}
.box_badges ul li {
    display: inline-block;
    background-color: #74d1c6;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    line-height: normal;
    color: #fff;
    padding: 3px 10px;
    font-weight: 600;
    font-size: 13px;
    font-size: 0.8125rem;
    min-width: 120px;
    margin-bottom: 5px;
}

#badge_level_1,
#badge_level_2,
#badge_level_3,
#badge_level_4,
#badge_level_5,
#badge_level_6 {
    width: 100px;
    height: 100px;
    margin: 0 auto 25px;
    position: relative;
}

.disabled_badge {
    opacity: 0.8;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.15s ease-in-out;
    transition: 0.15s ease-in-out;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
}
.disabled_badge:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    opacity: 1;
}
.disabled_badge i {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -45px;
    z-index: 9;
    width: 40px;
    height: 40px;
    color: rgba(0, 0, 0, 0.6);
    text-indent: 0;
    font-size: 40px;
    font-size: 2.5rem;
}
.disabled_badge i:before {
    margin: 0;
}
.disabled_badge:hover i {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    transform: translateY(20px);
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.badge_list_1 {
    position: relative;
    top: -2px;
    margin-left: 10px;
}

/*-------- 3.6 Pagination --------*/
.page-item.active .page-link {
    background-color: #4b9e63;
    border-color: #4b9e63;
}

.page-item.disabled .page-link {
    color: #999;
    border-color: #e1e8ed;
}

.page-link {
    color: #0086ad;
    border: 1px solid #e1e8ed;
}
.page-link:focus,
.page-link:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0086ad;
    border-color: #0086ad;
}

/*-------- 3.7 Cookie bar --------*/
#cookie-bar {
    background: rgba(0, 0, 0, 0.8);
    height: auto;
    line-height: 24px;
    color: #eeeeee;
    text-align: center;
    padding: 15px 0;
    font-weight: 400;
    font-size: 13px;
    font-size: 0.8125rem;
    font-weight: 500;
}
#cookie-bar p {
    margin: 0;
    padding: 0;
}
#cookie-bar a {
    color: #fff;
    display: inline-block;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    text-decoration: none;
    padding: 2px 10px;
    margin-left: 8px;
}
@media (max-width: 767px) {
    #cookie-bar a {
        /* For less than 767px */
        display: block;
        max-width: 150px;
        margin: auto;
        margin-bottom: 5px;
    }
}
#cookie-bar .cb-enable {
    background: #74d1c6;
}
#cookie-bar .cb-enable:hover {
    background: #fff;
    color: #74d1c6;
}
#cookie-bar .cb-disable {
    background: #990000;
}
#cookie-bar .cb-disable:hover {
    background: #bb0000;
}
#cookie-bar .cb-policy {
    background: #0086ad;
}
#cookie-bar .cb-policy:hover {
    background: #fff;
    color: #0086ad;
}
#cookie-bar.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
}
#cookie-bar.fixed.bottom {
    bottom: 0;
    top: auto;
}
@media (max-width: 767px) {
    #cookie-bar {
        /* For less than 767px */
        padding: 5px 0;
    }
}

/*-------- 3.8 Date dropper/time dropper --------*/
.form-control:disabled#booking_date,
.form-control[readonly]#booking_date,
.form-control:disabled#booking_time,
.form-control[readonly]#booking_time {
    background-color: #fff;
    opacity: 1;
}

div.datedropper,
.td-wrap {
    font-family: 'Poppins', Helvetica, sans-serif !important;
}

div.datedropper .picker .pick-lg ul.pick-lg-b li.pick-v:hover {
    text-decoration: none !important;
}

div.datedropper.primary:before,
div.datedropper.primary .pick-submit,
div.datedropper.primary .pick-lg-b .pick-sl:before,
div.datedropper.primary .pick-m,
div.datedropper.primary .pick-lg-h {
    background-color: #0086ad !important;
}

div.datedropper.primary .pick-y.pick-jump,
div.datedropper.primary .pick li span,
div.datedropper.primary .pick-lg-b .pick-wke,
div.datedropper.primary .pick-btn {
    color: #fd4741;
}

/*-------- 3.9 Faq --------*/
.box_style_cat {
    background: #fff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #e1e8ed;
    margin-bottom: 25px;
}

ul#cat_nav {
    list-style: none;
    margin: 0;
    padding: 0;
}
ul#cat_nav li {
    border-bottom: 1px solid #e1e8ed;
}
ul#cat_nav li i {
    font-size: 16px;
    font-size: 1rem;
    margin: 1px 10px 0 0;
    float: left;
}
ul#cat_nav li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}
ul#cat_nav li a {
    position: relative;
    color: #555;
    display: block;
    padding: 15px 10px;
}
ul#cat_nav li a:after {
    font-family: 'fontello';
    content: '\e89b';
    position: absolute;
    right: 15px;
    top: 15px;
}
ul#cat_nav li a span {
    font-size: 11px;
    font-size: 0.6875rem;
    color: #999;
}
ul#cat_nav li a:hover,
ul#cat_nav li a#active,
ul#cat_nav li a.active {
    color: #0086ad;
}
ul#cat_nav li:first-child a:hover,
ul#cat_nav li:first-child a#active,
ul#cat_nav li:first-child a.active {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}
ul#cat_nav li:last-child a:hover,
ul#cat_nav li:last-child a#active,
ul#cat_nav li:last-child a.active {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -ms-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
}

.card {
    border: 0;
    margin-bottom: 5px;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #e1e8ed;
    padding: 20px;
}

.card-body {
    padding-bottom: 0;
}

.card-header h5 {
    font-size: 16px;
    font-size: 1rem;
}
.card-header h5 a {
    border: 0;
    display: block;
    color: #999;
}
.card-header h5 a i.indicator {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: normal;
    float: right;
    color: #0086ad;
}

.text-center-margin {
    text-align: center !important;
    margin-bottom: 20px !important;
}
.text-left-margin {
    text-align: left!important;
    margin-bottom: 20px !important;
}
.text-left {
    text-align: left!important;
    margin-left: 10px !important;
}
.esante-plus {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin-top: 30px;
    position: relative;
}
.lockHeaderLogo {
    display: flex;
    align-items: center !important;
    align-content: center !important;
    justify-content: center !important;
    margin-bottom: 3.5em;
}
.row-eq-height {
    display: flex;
}
.image_slider {
    width: 300px !important;
    height: 300px !important;
    max-width: 100%;
    height: auto;
}

/*  new */
.about-review1 {
    margin-bottom: 30px;
    border: 1px solid #e1e8ed;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    padding: 25px;
    min-height: 260px;
    height: 100%;
}

@media (max-width: 767px) {
    .about-review1 {
        /* For less than 767px */
        min-height: inherit;
    }
}
.about-review1 .user_review1 {
    padding-left: 90px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 40px;
    
}
.about-review1 .user_review1 figure {
    position: absolute;
    left: 0;
    top: 25px !important;
    width: 45px;
    height: 45px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}
.about-review1 .user_review1 figure img {
    width: 50px;
    height: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
/* edit */
.user_review1 img {
    width: 80px !important;
    height: 80px !important;
    position: absolute !important;
    border-radius: 100%;
    border: 3px solid #54B06E;
 /* padding: 10px; */
}
/* edit end  */
.about-review1 .user_review1 h4 {
    font-size: 14px;
    font-size: 0.875rem;
    margin-bottom: 0;
    /* margin-left: .5em; */

    color: #777;
}
.about-review1 .user_review1 span {
    display: block;
    font-size: 12px;
    font-size: 0.75rem;
    color: #999;
    font-weight: normal;
}

.about-review1 .rating {
    color: #222;
    margin-bottom: 10px;
}
.about-review1 .rating i {
    color: #999;
}
.about-review1 .rating .voted {
    color: #ffc107;
}
.mm {
    width: 100px !important;
    height: 90px !important;
}
.nn {
    width: 20px !important;
    height: 20px !important;
}

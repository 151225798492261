.admin_app .text, .item{
  font-family: 'Cairo', sans-serif !important;
  font-size: 1rem;
}
.sidebarEsante > div{
  padding: 0 !important;
  margin: 0 !important;
}
.layoutEsante > div{
  padding: 0 !important;
  margin: 0 !important;
}
.Layout-appFrame-2{
  margin-top: 0 !important;
}
.jss2{
  padding: 0 !important;
  margin: 0 !important;
}
.jss4{
  padding: 0 !important;
  margin: 0 !important;
}
.MuiAppBar-colorSecondary{
  display: none !important;
  width: 0px !important;
  height: 0px !important;
}
.MuiDrawer-docked{
  width: 0 !important;
  display: none !important;
}
.RaSidebar-drawerPaper-120{
  width: 0 !important;
}
div[class^="Layout-content-"]{
  padding: 0 !important;
  margin: 0 !important;
}
div[class^="Layout-"]{
  padding: 0 !important;
  margin: 0 !important;
}
div[class^="Layout-appFrame-"]{
  padding: 0 !important;
  margin: 0 !important;
}
.segment_admin_main{
  top: 45px !important;
  left: 45px !important;
  width: 96% !important;
  border: none !important;
}
@media (max-width: 575px) {
  .segment_admin_main{
    top: 45px !important;
    left: 45px !important;
    width: 90% !important;
    border: none !important;
  }
}
.sidePanel {
  width: 50px !important;
  padding-left: 0.6em;
  padding-top: 0.3em;
}
.spanItem{
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.spanPadmin {
  text-transform: uppercase;
  font-family: 'Cairo', sans-serif;
  font-size: 1.2rem;
  color: #ffffff;
}
.spanSadmin {
  text-transform: capitalize;
  font-family: 'Cairo', sans-serif;
  font-size: .7rem;
  color: #ffffff;
}